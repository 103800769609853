import React from "react";
import {createButtonlLink, createExternalLink, getImage} from "../../../utils/helper";
import PropTypes, {oneOfType} from "prop-types";

const PageHeading = (props) => {

    const {logo, title, headline, toolLink, betaToolLink, color} = props;

    const makeLink = (toolLink, color, isBeta = false) => {
        if (Array.isArray(toolLink)) {

            return (
                <div className="tool-page-links">
                    {toolLink.map((link, index) => {

                        if (isBeta) {
                            return (
                                <div key={index}>
                                    {createButtonlLink(link.url, `BETA ${link.text}`, "white", color, true)}
                                </div>)
                        }

                        return (
                            <div key={index}>
                                {createExternalLink(link.url, link.text, color)}
                            </div>)
                    })}
                </div>
            )
        } else//string
        {
            if (isBeta) {

                return (
                    createButtonlLink(toolLink, `BETA`, "white", color, true)
                )
            }
            return createExternalLink(toolLink, toolLink, color);
        }
    };

    return (
        <div>
            {logo &&
            <div className="tool-page-logo">
                <img src={getImage(`${logo}`)} alt={title}/>
            </div>
            }

            <div className="tool-page-title"><h1>{title}</h1></div>

            {headline && (
                <div className="tool-page-headline">{headline}</div>
            )}

            {toolLink &&
            <div className="tool-page-toolLink">
                {makeLink(toolLink, color)}
            </div>
            }

            {betaToolLink &&
            <div className="tool-page-betaToolLink">
                {makeLink(betaToolLink, color, true)}
            </div>
            }
        </div>
    )


};

PageHeading.propTypes = {
    logo: PropTypes.string,
    title: PropTypes.string.isRequired,
    headline: PropTypes.string,
    toolLink: oneOfType([PropTypes.string, PropTypes.array]),
    betaToolLink: oneOfType([PropTypes.string, PropTypes.array]),
    color: PropTypes.string.isRequired
};

export default PageHeading;