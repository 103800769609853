import React from "react";
import {getImage} from "../../../utils/helper";
import {FaExternalLinkAlt} from "react-icons/fa";

const Awards = (props) => {

    const {awards, color} = props;

    if (awards) {
        return (

            <React.Fragment>
                <h3>Awards</h3>
                <div className="awards-wrapper">
                    {awards.map((award, index) => {
                        return (
                            <div className="award" key={index}>

                                {award.img && <img src={getImage(award.img)}/>}
                                {award.title && <div className="award-title">{award.title}</div>}
                                {award.link && (
                                    <a href={award.link} className="external-link" target="_blank">
                                        <FaExternalLinkAlt size={18} color={color}/>
                                    </a>
                                )}
                            </div>
                        )
                    })}
                </div>

            </React.Fragment>
        )
    }
    return null;


};

export default Awards;