import React, {useState} from 'react'
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import {FaHome} from "react-icons/fa";
import Home from "./components/home/Home/Home";
import ToolPage from "./components/tool/ToolPage/ToolPage";
import {NotFoundPage} from "./utils/notFoundPage";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import FilterContext from './core/FilterContext';

const routes = [
    {path: '/', name: 'Home', Component: Home},
    {path: '/tool/:toolPath', name: 'ToolPage', Component: ToolPage},
];


function App() {
    const [filterContext, setFilterContext] = useState();

    return (
        <FilterContext.Provider value={[filterContext, setFilterContext]}>
            <Router>

                <Navbar bg="dark" variant="dark" fixed="top" expand="md">

                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <Link to="/" className="nav-link"><FaHome size={30}/></Link>
                        </NavItem>
                    </Nav>
                    <NavbarBrand><a href="http://inside.disco.unimib.it/" target="_blank">Insid&s Lab</a></NavbarBrand>

                </Navbar>

                <Switch>
                    {routes.map(({path, Component}) => (
                        <Route key={path} exact path={path} component={Component}/>
                    ))}
                    <Route component={NotFoundPage}/>
                </Switch>

            </Router>
        </FilterContext.Provider>
    );
}

export default App;
