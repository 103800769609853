import React from "react";
import {Link} from "react-scroll/modules";
import {hexToRGB} from "../../../utils/helper";

const SideNav = (props) => {
    const {data, color} = props;

    const createScrollLink = (title, target, color) => {
        return (
            <Link className="sideNav-section-link"
                  to={target}
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
            >
                <span className="sideNav-section-title"
                      style={{
                          backgroundColor: hexToRGB(color, .4),
                      }}>
                    {title}
                </span>
                <span className="sideNav-section-dot"
                      style={{
                          borderColor: color,
                          backgroundColor: color,
                      }}
                />
            </Link>
        )
    };



    let dataCount = 0;

    if (data.about) dataCount++;
    if (data.howItWorks) dataCount++;
    if (data.publications) dataCount++;
    if (data.thesis) dataCount++;
    if (data.awards) dataCount++;
    if (data.repository) dataCount++;

    if (dataCount > 1) {
        return (
            <div>
                {data.about && createScrollLink("about", "about", color)}
                {data.howItWorks && createScrollLink("how it works", "howItWorks", color)}
                {data.publications && createScrollLink("publications", "publications", color)}
                {data.thesis && createScrollLink("thesis", "thesis", color)}
                {data.awards && createScrollLink("awards", "awards", color)}
                {data.repository && createScrollLink("repository", "repository", color)}

            </div>

        )

    }
    return null;


};

export default SideNav;