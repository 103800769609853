import React, {Component} from "react";

export class NotFoundPage extends Component {
    render() {
        return (
            <div class="notFound">
                This page does not exist
            </div>
        );
    }
}