import React, {useState} from "react";
import ToolCardGrid from "../../tool/toolCardGrid";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardsFilter from "../CardsFilter/CardsFilter";
import "./Home.module.scss";


const selectableOptions = [
    {type: "main", title: "Main Tools"},
    {type: "developing", title: "Developing Tools"},
    {type: "secondary", title: "Secondary Tools"},
];


const Home = () => {

    const [selection, setSelection] = useState([]);

    const selectList = (selectedItem) => {

        const selected = selectableOptions.filter((option) => option.type === selectedItem)[0];
        setSelection(selected);
    };

    return (

        <Container fluid>
            <Row>
                <Col>

                    <h1>Tools</h1>

                    <CardsFilter
                        filterOptions={["main", "developing", "secondary"]}
                        onClickHandler={selectList}
                    />

                    {selection.type && selection.title &&
                    <ToolCardGrid type={selection.type} title={selection.title}/>
                    }
                </Col>
            </Row>
        </Container>


    )
};

export default Home