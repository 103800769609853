import React from "react";
import {createList, getImage} from "../../../utils/helper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MainDescription = (props) => {
    const {mainDescription} = props;

    const getDescription = (description) => {
        const descriptionPage = description.page;
        if (descriptionPage && descriptionPage !== "") {
            return <div
                dangerouslySetInnerHTML={{
                    __html: descriptionPage
                }}/>
        }
        return <div
            dangerouslySetInnerHTML={{
                __html: description.grid
            }}/>

    };

    const getIO = (io) => {
        if (io && (io.input || io.output)) {
            return (
                <div className="tool-page-io">
                    {io.input && <div><strong>input:</strong> {io.input}</div>}
                    {io.output && <div><strong>output:</strong> {io.output}</div>}
                </div>
            )
        }

        return null;

    }

    const getTeam = (team) => {
        let teamCurrent;
        let teamPast;

        if (team) {
            if (Array.isArray(team)) {
                teamCurrent = createList(team, true);
            } else {
                if (team.current) {
                    teamCurrent = createList(team.current, true);
                }

                if (team.past) {
                    teamPast = createList(team.past, true);
                }
            }

        }

        if (teamCurrent || teamPast) {
            return (
                <div className="tool-page-team">
                    {teamCurrent && (
                        <div>
                            <strong>Team:</strong>
                            {teamCurrent}
                        </div>
                    )}
                    {teamPast && (
                        <div>
                            <strong>Past collaborators:</strong>
                            {teamPast}
                        </div>
                    )}
                </div>
            )
        }

        return null;
    }

    const getScreenshots = (screenshots) => {
        if (screenshots) {
            return (
                <div className="tool-page-screenshots">
                    {screenshots.map((screen, index) => <div key={index}><img src={getImage(screen)}/></div>)}
                </div>
            )
        }
        return null;

    }


    return (
        <>
            <Row className="justify-content-md-center ">
                <Col>
                    {getDescription(mainDescription.description)}
                    {getIO(mainDescription.io)}
                </Col>

                {mainDescription.team &&
                <Col md="auto">
                    {getTeam(mainDescription.team)}
                </Col>
                }

            </Row>

            {mainDescription.screenshots &&
            <Row className="justify-content-md-center ">
                <Col>
                    {getScreenshots(mainDescription.screenshots)}
                </Col>
            </Row>

            }
        </>
    )

};

export default MainDescription;