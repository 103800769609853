import React, {useContext, useEffect, useState} from "react";
import style from "./CardsFilter.module.scss";
import clsx from "clsx";
import FilterContext from "../../../core/FilterContext";

const CardsFilter = (props) => {


    const {filterOptions, onClickHandler} = props;
    const [activeOption, setActiveOption] = useState();
    const [filterContext, setFilterContext] = useContext(FilterContext);

    useEffect(() => {
        setActiveOption(filterContext ? filterContext : filterOptions[0]);
        onClickHandler(filterContext ? filterContext : filterOptions[0]);
    }, []);

    const onClick = (option) => {
        setFilterContext(option);
        setActiveOption(option);
        onClickHandler(option);
    };

    return (
        <div className={style.switch}>
            {
                filterOptions.map((option) => <div key={option}
                                                   className={clsx(
                                                       style.option,
                                                       {[style.active]: activeOption === option}
                                                   )}
                                                   onClick={() => onClick(option)}>{option}</div>)

            }
        </div>
    )

};


export default CardsFilter;