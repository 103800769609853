import React from "react";
import {NextArrow, PrevArrow} from "../../slider/slider";
import Slider from "react-slick/lib";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getImage, getVideoSrc, getVideoType} from "../../../utils/helper";

const settings = {
    dots: true,
    infinite: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
};

const HowItWorks = (props) => {

    const {description} = props;
    const {slider, video} = description;

    if (description) {

        return (
            <React.Fragment>
                <h3>How it works</h3>
                {slider &&
                <div className="slider-wrapper">
                    <Slider {...settings}>
                        {slider.map((step, index) => {
                                return (
                                    <div key={index}>
                                        <Row>
                                            <Col md="6">
                                                {step.stepName && <div className="how-stepName">{step.stepName}</div>}

                                                {step.description && <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: step.description
                                                    }}/>
                                                }
                                            </Col>

                                            <Col md="6">
                                                {step.screenshot && <img key={index} src={getImage(step.screenshot)}/>}
                                            </Col>

                                        </Row>
                                    </div>

                                )
                            }
                        )}

                    </Slider>
                </div>
                }

                {video && video.file &&
                <div className="slider-wrapper">
                    <video style={{width: "100%"}} controls preload="metadata"
                           poster={video.poster ? getImage(video.poster) : null}
                    >
                        {video.file.map((item) => {
                            return (<source key={item} src={getVideoSrc(item)} type={getVideoType(item)}/>)
                        })
                        }
                        Your browser does not support the video tag.
                    </video>
                    {video.description &&
                    <div className="video-caption">{video.description}</div>
                    }
                </div>
                }
            </React.Fragment>
        )
    }

    return null;

};

export default HowItWorks;