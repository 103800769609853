import React from "react";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";

export function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <FiChevronRight
            className={className}
            color="black" size="30"
            onClick={onClick}
            style={{...style, width: "auto", height: "auto", right: 0}}
        />
    );
}

export function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <FiChevronLeft
            className={className}
            color="black" size="30"
            onClick={onClick}
            style={{...style, width: "auto", height: "auto", left: 0}}
        />
    );
}