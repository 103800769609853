import React from "react";
import ToolData from "../../../data/tool"
import {createExternalLink, getImage} from "../../../utils/helper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PrevNext from "./PrevNext";
import SideNav from "./SideNav";
import PageHeading from "./PageHeading";
import MainDescription from "./MainDescription";
import HowItWorks from "./HowItWorks";
import Publications from "./Publications";
import Awards from "./Awards";
import Thesis from "./Thesis";


const ToolPage = (props) => {

    const {match} = props;

    let tool = ToolData.filter(item => (item.meta.path) === match.params.toolPath)[0];

    const {meta, content} = tool;
    const {about, howItWorks, publications, thesis, awards, repository} = content;


    return (
        <div id="tool-page" className="tool-page">

            <Container fluid>
                <div className="tool-page-prevNext">
                    <PrevNext currentTool={tool}/>
                </div>
            </Container>

            <div className="tool-page-sideNav">
                <SideNav data={content} color={meta.mainColor}/>
            </div>

            <div id="about">

                <Container fluid>
                    <Row>
                        <Col className="tool-page-header">

                            <PageHeading
                                logo={meta.logo}
                                title={content.about.title}
                                headline={content.about.headline}
                                toolLink={content.about.toolLink}
                                betaToolLink={content.about.betaToolLink}
                                color={meta.mainColor}
                            />

                        </Col>
                    </Row>
                </Container>

                <div className=" tool-page-description ">
                    <Container>
                        <MainDescription
                            mainDescription={about}
                        />
                    </Container>
                </div>

            </div>

            {howItWorks &&
            <div id="howItWorks" className="tool-page-how">
                <Container fluid>
                    <HowItWorks description={howItWorks}/>
                </Container>
            </div>
            }

            {publications &&
            <div id="publications" className="tool-page-publications ">
                <Container>
                    <Publications
                        publications={publications}
                        color={meta.mainColor}/>
                </Container>
            </div>
            }

            {thesis &&
            <div id="thesis" className="tool-page-publications ">
                <Container>
                    <Thesis
                        thesis={thesis}
                        color={meta.mainColor}/>
                </Container>
            </div>
            }

            {awards &&
            <div id="awards" className="tool-page-awards">
                <Container>
                    <Awards awards={awards} color={meta.mainColor}/>
                </Container>
            </div>
            }

            {repository &&
            <div id="repository" className="tool-page-repository">
                <Container>
                    <img className="logo-mini" src={getImage("bitbucket.png")} alt="bitbucket"/>
                    {createExternalLink(repository, `${about.title} repository`)}
                </Container>
            </div>
            }

        </div>
    );

}

export default ToolPage;
