import * as React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {getImage, hexToRGB} from "../../utils/helper";

export default class ToolCard extends React.Component {
    render() {
        const {path, mainColor, logo, title, headline, description} = this.props;
        return (

            <Link to={`/tool/${encodeURI(path)}`} className="tool-card"
                  style={{
                      backgroundColor: hexToRGB(mainColor, .15),
                      borderColor: hexToRGB(mainColor, .15),
                  }}
            >
                {logo &&
                <div className="tool-card-logo">
                    <img src={getImage(`${logo}`)} alt={title}/>
                </div>
                }
                <div className="tool-card-body">

                    <div className="tool-card-title">{title}</div>
                    <div className="tool-card-headline">{headline}</div>
                    <div className="tool-card-description"
                         dangerouslySetInnerHTML={{
                             __html: description
                         }}/>

                </div>
                <div className="tool-card-discover"
                     style={{
                         color: mainColor,
                     }}>
                    Discover {title}
                </div>
            </Link>

        );
    }
}

ToolCard.propTypes = {
    path: PropTypes.string.isRequired,
    mainColor: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    headline: PropTypes.string,
    description: PropTypes.string.isRequired,
};