import React from "react";
import {getImage} from "../../../utils/helper";
import ToolData from "../../../data/tool";
import {NavLink} from "react-router-dom";

const PrevNext = (props) => {

    const {currentTool} = props;

    const filteredToolList = ToolData.filter((tool) => tool.meta.type === currentTool.meta.type);

    const currentIndex = filteredToolList.findIndex(function (tool, i) {
        return tool.meta.path === currentTool.meta.path
    });

    const buildLink = (item) => {
        return (
            <NavLink to={item.meta.path}>
                {item.meta.logo &&
                <img src={getImage(item.meta.logo)} alt={item.content.about.title}/>
                }
                {item.content.about.title}
            </NavLink>
        )
    };

    return (
        <>
            {filteredToolList[currentIndex - 1] &&
            <div className="prev">
                {buildLink(filteredToolList[currentIndex - 1])}
            </div>
            }
            {filteredToolList[currentIndex + 1] &&
            <div className="next">
                {buildLink(filteredToolList[currentIndex + 1])}
            </div>
            }
        </>
    )

};

export default PrevNext;