import React from "react";
import {FaExternalLinkAlt, FaRegFilePdf} from "react-icons/fa";


export function getImage(path) {
    let image;
    try {
        image = require(`../img/${path}`)
        // do stuff
    } catch (ex) {
        image = require(`../img/placeholder.png`)
    }

    console.log(image);
    return image.default;
}

export function getFile(path) {
    let file;

    try {
        file = require(`../doc/${path}`);
    } catch (e) {
        return null
    }
    return file.default;
}

export function getVideoSrc(fileName) {
    try {
        const video = require(`../video/${fileName}`);
        return video;
    } catch {
        return;
    }
}

export function getVideoType(fileName) {
    const ext = fileName.split('.').pop();
    return `video/${ext}`;
}

export function hexToRGB(hex, alpha = 1) {
    if (hex) {
        let parseString = hex;
        if (hex.startsWith('#')) {
            parseString = hex.slice(1, 7);
        }
        if (parseString.length !== 6) {
            return null;
        }
        const r = parseInt(parseString.slice(0, 2), 16);
        const g = parseInt(parseString.slice(2, 4), 16);
        const b = parseInt(parseString.slice(4, 6), 16);
        if (isNaN(r) || isNaN(g) || isNaN(b)) {
            return null;
        }
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return false;
};

export function createList(items, sortLexicographic) {

    let sortedItems;
    if (sortLexicographic) {
        sortedItems = items.sort((a, b) => {
            const aSurname = a.split(". ")[1] || a;
            const bSurname = b.split(". ")[1] || b;

            if (aSurname < bSurname) {
                return -1;
            }
            if (aSurname > bSurname) {
                return 1;
            }
            return 0;
        });
    } else {
        sortedItems = items;
    }

    return (
        <ul>
            {sortedItems.map((item, index) => {
                return <li key={index}>{item}</li>
            })}
        </ul>)
}

export function createExternalLink(link, text, color) {
    const textLink = (text && text.replace(/^.*:\/\//i, '')) || link;

    return (
        <a href={link} target="_blank"
           style={{color: color}}
        >{textLink}</a>
    )
}

export function createButtonlLink(link, text, color, background, showIcon) {
    const textLink = (text && text.replace(/^.*:\/\//i, '')) || link;
    return (
        <a href={link} className="btn" target="_blank"
           style={{color: color, background: background}}
        >
            {textLink}
            {showIcon &&
            <FaExternalLinkAlt size={14} color={color}/>
            }
        </a>
    )
}

export const getResourceLink = (resource, color) => {

    if (resource) {
        if (resource.indexOf("http") === -1 && resource.indexOf(".pdf") !== -1) {
            const file = getFile(resource);
            if (file)
                return (<a href={file} className="external-link" target="_blank">
                    <FaRegFilePdf size={18} color={color}/>
                </a>)

        } else {
            return (<a href={resource} className="external-link" target="_blank">
                    <FaExternalLinkAlt size={18} color={color}/>
                </a>
            )
        }
    }
        return null;

};