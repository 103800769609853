import * as React from "react";
import ToolCard from "./toolCard";
import ToolData from "../../data/tool"

export default class ToolCardGrid extends React.Component {
    render() {
        const {type, title} = this.props;

        const filteredList = ToolData.filter(tool => tool.meta.type === type);
        let toolList = filteredList.map((tool, index) => {
            const meta = tool.meta;
            const about = tool.content.about;
            return (
                <div key={index} className="wrapper">

                    <ToolCard
                        path={meta.path}
                        mainColor={meta.mainColor}
                        logo={meta.logo}
                        title={about.title}
                        headline={about.headline}
                        description={about.description.grid}
                    />

                </div>
            )
        });

        return (
            toolList.length && (
                <div className="tool-list-wrapper">
                    {/*<h2> {title}</h2>*/}
                    <div className="tool-list">
                        {toolList}
                    </div>
                </div>)
        );
    }
}
