import React from "react";
import {getResourceLink} from "../../../utils/helper";

const Publications = (props) => {

    const {publications, color} = props;


    const getPublications = (publications, color) => {
        return publications.map((publication, index) =>
            <div key={index}>
                {getAbout(publication)}
                {getBibText(publication, color)}
            </div>
        )
    };

    const getAbout = (publication) => {
        if (publication.about)
            return <div className="tool-page-publications-about">{publication.about}</div>;

        return null
    };

    const getBibText = (publication, color) => {
        return <ul>{getBibTextTitle(publication.bibText, color)}</ul>
    };

    const getBibTextTitle = (bibText, color) => {
        return bibText.map((item, index) =>
            <li key={index}>
                {item.title}
                {getResourceLink(item.link, color)}
            </li>
        )
    };


    if (publications) {
        return (

            <React.Fragment>
                <h3>Publications</h3>
                {getPublications(publications, color)}
            </React.Fragment>

        )
    }
    return null;


};

export default Publications;