import React from "react";
import {getResourceLink} from "../../../utils/helper";

const Thesis = (props) => {

    const {thesis, color} = props;


    if (thesis) {
        return (

            <React.Fragment>
                <h3>Thesis</h3>
                <ul>
                    {
                        thesis.map((item) => {
                            return (
                                <li key={item.title}>
                                    <span className="font-weight-bold small">
                                        {item.date} &bull;
                                    </span> {item.title} ({item.author})

                                    {getResourceLink(item.link, color)}
                                </li>
                            )

                        })
                    }
                </ul>
            </React.Fragment>

        )
    }
    return null;


};

export default Thesis;